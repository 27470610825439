<template>
    <div class="page1">
        <div>
            角色名称：{{role_name}}
        </div>
        <STable
                :tableTitle="tableTitle"
                :tableData="tableData"
                :showCheck="false"
                @checkAll="checkAll"
        :checkedAllVal="checkedAllVal">
            <template slot-scope="val" slot="edit">
                <el-checkbox v-model="val.msg&&val.msg.visible" @change="checkBoxChange(val.msg.visible,'menuKey',val.msg.button)">可见</el-checkbox>
            </template>
            <template slot-scope="val" slot="sort">
                <el-checkbox :disabled="val.msg&&!val.msg.visible" v-model="item.checked" @change="checkBoxChange(item.checked,'menuButtonKey')" v-for="(item,index) in val.msg&&val.msg.button" :key="index">
                    {{item.button_name}}</el-checkbox>
            </template>
        </STable>
        <div class="mt20">
            <el-button @click="$router.go(-1)">取消</el-button>
            <el-button type="primary" @click="saveArea">确定</el-button>

        </div>
    </div>
</template>

<script>
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import STable from '@/components/STable'
  export default {
    name: 'Login',
    data() {
      return {
        visibleList:[],
        tableData:[],
        tableTitle:[
          {
            label: '一级菜单名称',
            prop: 'menu_name'
          },
          {
            label: '二级菜单名称',
            prop: 'menu_name'
          },
          {
            label: '三级菜单名称',
            prop: 'menu_name',

          },

          {
            label: '菜单可见性',
            prop:'button',
            type:'check',
            key:'menuKey'
          },
          {
            label: '菜单按钮权限',
            prop:'button',
            type:'check',
            key:'menuButtonKey'
          },
        ],
        checkedAllVal:{
          menuKey:false,
          menuButtonKey:false
        },
        buttonList:[]
      }
    },
    watch: {},
    created() {

      let data = this.$route.query
      this.role_id = data.role_id
      this.role_name = data.role_name
      this.roleMenuDetail()

    },
    components: {STable, EDialog, EButton},
    beforeMount() {

    },
    methods: {
      checkBoxChange(checked,key,button){
        if(!checked){
            this.checkedAllVal[key]=false
          if(button){
            button.forEach((item)=>{
              item.checked=false
            })
          }

        }else{
          if(key=='menuKey'){
            let arr=[]
            this.tableData.forEach((item)=>{
              if(item.children){
                item.children.forEach((item2)=>{
                  if(item2.children){
                    item2.children.forEach((item3)=>{
                      if(item3.visible){
                        arr.push(item3)
                      }
                    })
                  }else{
                    if(item2.visible){
                      arr.push(item2)
                    }
                  }
                })
              }else{
                if(item.visible){
                  arr.push(item)
                }
              }
            })
            if(arr.length===this.visibleList.length){
              this.checkedAllVal.menuKey=true
            }
          }else{
            let arr=[]
            this.tableData.forEach((item)=>{

              if(item.children){
                item.children.forEach((item2)=>{
                  if(item2.children){
                    item2.children.forEach((item3)=>{
                      if(item3.button){
                        item3.button.forEach((buttonItem)=>{
                          if(buttonItem.checked){
                            arr.push(buttonItem)
                          }
                        })

                      }
                    })
                  }
                })
              }
            })
            if(arr.length===this.buttonList.length){
              this.checkedAllVal.menuButtonKey=true
            }
          }
        }
      },
      getNum(obj){
      },
      checkAll(obj) {
        if (obj.key == 'menuKey') {
          if (obj.value) {
            this.tableData.forEach((item) => {
              if (item.children) {
                item.children.forEach((item2) => {
                  if (item2.children) {
                    item2.children.forEach((item3) => {
                      item3.visible = true
                    })
                  } else {
                    item2.visible = true
                  }
                })
              } else {
                item.visible = true
              }
            })
          } else {
            this.tableData.forEach((item) => {
              if (item.children) {
                item.children.forEach((item2) => {
                  if (item2.children) {
                    item2.children.forEach((item3) => {
                      item3.visible = false
                    })
                  } else {
                    item2.visible = false
                  }
                })
              } else {
                item.visible = false
              }
            })
          }

        } else if (obj.key == 'menuButtonKey') {
          if (obj.value) {
            this.tableData.forEach((item) => {
              if (item.children) {
                item.children.forEach((item2) => {
                  if (item2.children) {
                    item2.children.forEach((item3) => {
                      if (item3.button) {
                        item3.button.forEach((buttonItem) => {
                          buttonItem.checked = true
                        })
                      }
                    })
                  }
                })
              }
            })
          } else {
            this.tableData.forEach((item) => {
              if (item.children) {
                item.children.forEach((item2) => {
                  if (item2.children) {
                    item2.children.forEach((item3) => {
                      if (item3.button) {
                        item3.button.forEach((buttonItem) => {
                          buttonItem.checked = false
                        })
                      }
                    })
                  }
                })
              }
            })
          }
        }
      },
      async roleMenuDetail() {
        let res = await Http.roleMenuDetail({role_id: this.role_id})
        this.tableData= res.data.list
        this.tableData.forEach((item)=>{

          if(item.children){
            item.children.forEach((item2)=>{
              if(item2.children){
                item2.children.forEach((item3)=>{
                  if(item3.button){
                    this.buttonList.push(...item3.button)
                  }
                  this.visibleList.push({menu_id:item3.menu_id,visible:item3.visible})
                })
              }else{
                this.visibleList.push({menu_id:item2.menu_id,visible:item2.visible})
              }
            })
          }else{
            this.visibleList.push({menu_id:item.menu_id,visible:item.visible})
          }
        })
        let arr=[]
        let arr2=[]
        this.buttonList.forEach((item)=>{
          if(item.checked){
            arr.push(item)
          }
        })
        this.visibleList.forEach((item)=>{
          if(item.visible){
            arr2.push(item)
          }
        })
        if(arr.length===this.buttonList.length){
          this.checkedAllVal.menuButtonKey=true
        }
        if(arr2.length===this.visibleList.length){
          this.checkedAllVal.menuKey=true
        }
        this.$forceUpdate()
      },
      handleCheckedCitiesChange(city) {
        if(city.checked){
          city.children.forEach((item)=>{
           item.checked=true
          })
        }else{
          city.children.forEach((item)=>{
            item.checked=false
          })
        }
      },
      cityCheck(item,city){
        if(!item){
          city.checked=false
        }else{
          let arr=[]
          city.children.forEach((item)=>{
            if(item.checked){
              arr.push(item)
            }
          })
          if(arr.length==city.children.length){
            city.checked=true
          }
        }
      },
      handleCheckAllChange() {
      },
      async saveArea(){
        let res = await Http.editRoleMenu({data:JSON.stringify({list:this.tableData,role_id:this.role_id})})
        if(res.flag==1){
          this.$message.success(res.msg)
          this.$router.go(-1)
        }
      }
    }
  }
</script>

<style lang="scss">

</style>

